import React from "react";
import ConsultingForm from "../ConsultingForm/ConsultingForm";

const Consultinencey = (props) => {
  return (
    <section className={`contact-section ${props.contactClass}`}>
      <div className="content-area clearfix">
        <div className="contact-info-col">
          <div
            className="contact-info"
            style={{
              color: "WHITE",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            <ul>
              <li>
                <i className="fi flaticon-home-3"></i>
                <h4
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  প্রধান কার্যালয়
                </h4>
                <p
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                  }}
                >
                  ২৮/এ (নিচতলা), সেগুন বাগিচা, (শিল্পকলা একাডেমিকর দক্ষিণ-পূর্ণ
                  কোণে), ঢাকা- ১০০০
                </p>
              </li>
              <li>
                <i className="fi flaticon-email"></i>
                <h4
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  ইমেইল
                </h4>
                <p
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                  }}
                >
                  contact@smartlegalaction.com
                </p>
              </li>
              <li>
                <i className="fi flaticon-support"></i>
                <h4
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  মোবাইল
                </h4>
                <p
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                  }}
                >
                  +8801717494909
                </p>
              </li>
              <li>
                <i className="fi flaticon-clock"></i>
                <h4
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >
                  অফিসের সময়সূচি
                </h4>
                <p
                  style={{
                    color: "WHITE",
                    fontSize: "20px",
                  }}
                >
                  শনি-বৃহস্পতি: সকাল ১০:০০ – রাত ১১:০০
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="contact-form-col">
          <div className="section-title-s2">
            <div className="icon">
              <i className="fi flaticon-balance"></i>
            </div>
            <span
              style={{
                color: "WHITE",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              যোগাযোগ ফরম
            </span>
            <h2>পরামর্শের জন্য নিচের ফরমটি পূরণ করুন</h2>
            <p
              style={{
                color: "WHITE",
                fontSize: "20px",
              }}
            >
              ফরমটি পূরণ করে জমা দেওয়ার পর আমাদের পক্ষ থেকে যোগাযোগ করা হবে
            </p>
          </div>

          <div className="contact-form">
            <ConsultingForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Consultinencey;
