import React from "react";
import abimg from "../../images/ceo.jpeg";
import signature from "../../images/signature.png";
import { Link } from "react-router-dom";

const About = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="about-section section-padding">
      <div className="container">
        <div className="row">
          <div className="col col-lg-4 col-12">
            <div className="left-col">
              <div className="section-title">
                <div className="icon">
                  <i className="fi flaticon-home-3"></i>
                </div>
                <span
                  style={{
                    color: "#0473EA",
                    fontSize: "20px",
                    fontWeight: "bold",
                  }}
                >'স্মার্ট লিগ্যাল একশন'</span>
                <h2>কোন প্রচলিত প্রথার ল' ফার্ম নয়৷</h2>
                <p>
                  জীবনের সংকটময় মুহূর্তে বন্ধু হিসেবে তাদের কাঙ্ক্ষিত সময়ের
                  মধ্যে সমাধান নিশ্চিত করাই আমাদের উদ্দেশ্য৷
                </p>
                <Link onClick={ClickHandler} to="/about" className="more-about">
                  বিস্তারিত <i className="fi flaticon-next-1"></i>
                </Link>
              </div>
            </div>
          </div>
          <div className="col col-lg-3 col-12">
            <div className="mid-col">
              <img src={abimg} alt="" width={300} height={300} />
            </div>
          </div>
          <div className="col col-lg-4 col-12">
            <div className="right-col padding-top0">
              <p>
                আইন সেবার প্রচলিত প্রথা ভেঙে নতুন সরল পদ্ধতিতে মানুষের
                সমস্যাগুলোকে সমাধানের জন্য আমরা প্রযুক্তি নির্ভর প্লাটফর্ম তৈরী
                করেছি৷
              </p>
              <div className="quoter">
                <h4>রাশিদা চৌধুরী নীলু </h4>
                <div className="row">
                  <div className="col col-lg-10 col-12">
                    <div className="">
                      চেয়ারম্যান, স্মার্ট লিগ্যাল একশন <br />
                      <div className="row">
                        <div className="col col-lg-8 col-12">
                          <div className="text-center">ও</div>
                        </div>
                      </div>
                      আইনজীবী, আপিল বিভাগ, বাংলাদেশ সুপ্রীম কোর্ট
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="signature">
                <img src={signature} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
